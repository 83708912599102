import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { ServicesList } from '../components';

import styles from './services.module.scss';

const FloorPage = () => {
  const { contentfulFloorPage } = useStaticQuery(graphql`
    {
      contentfulFloorPage {
        title
        subtitle
        floorList {
          name
          description
          price
        }
      }
    }
  `);

  return (
    <main>
      {/* HERO */}
      <div className={`${styles.hero} ${styles.floor}`}>
        <div className="container">
          <h1 className="heading">{contentfulFloorPage.title}</h1>
          <h2 className="subheading">{contentfulFloorPage.subtitle}</h2>
        </div>
      </div>
      {/* Content */}
      <div className={styles.content}>
        <div className="container">
          <ServicesList data={contentfulFloorPage.floorList} />
        </div>
      </div>
    </main>
  );
};

export default FloorPage;
